import React from 'react';
import styled from 'styled-components';
import { colors } from '@/theme';

type FormHelpTextProps = {
  isError?: boolean;
  center?: boolean;
  children: React.ReactNode;
};

const StyledSpan = styled.span<FormHelpTextProps>`
  color: ${(props) => (props.isError ? colors.red500 : colors.gray400)};
  display: block;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  max-width: calc(100vw - 30px);
  margin-top: 5px;
`;

export const FormHelpText: React.FC<FormHelpTextProps> = ({
  isError,
  center,
  children,
}) => (
  <StyledSpan isError={isError} center={center}>
    {children}
  </StyledSpan>
);
