import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SunFull } from '@/assets/svg/sun-full.svg';
import { ReactComponent as SunFullCheck } from '@/assets/svg/sun-full-check.svg';

const StyledLoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  background: rgba(245, 241, 238, 0.9);
  z-index: 10;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  svg.spinning {
    animation: spin 2000ms linear infinite;
  }
`;

type FullPageLoadingProps = {
  finished?: boolean;
};

export const FullPageLoading: React.FC<FullPageLoadingProps> = ({
  finished = false,
}) => (
  <StyledLoadingContainer>
    {!finished ? <SunFull className="spinning" /> : <SunFullCheck />}
  </StyledLoadingContainer>
);
