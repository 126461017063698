export const colors = {
  gray100: '#F5F1EE',
  gray200: '#B9B5B6',
  gray300: '#A19C9D',
  gray400: '#716E6F',
  gray500: '#464343',
  gray800: '#333232',
  red500: '#EA3E36',
  white: '#fff',
};
