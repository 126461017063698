import { createSlice } from '@reduxjs/toolkit';

export type RedeemState = {
  code: string | null;
  type: 'redeemable-code' | 'invite-token' | null;
};

const initialState: RedeemState = {
  code: null,
  type: null,
};

export const redeemSlice = createSlice({
  name: 'redeem',
  initialState,
  reducers: {
    onCodeFulfilled: (
      state,
      action: {
        type: string;
        payload: {
          code: string | null;
          type: 'redeemable-code' | 'invite-token';
        };
      },
    ) => {
      state.code = action.payload.code;
      state.type = action.payload.type;
    },
  },
});

export const { onCodeFulfilled } = redeemSlice.actions;

export default redeemSlice.reducer;
