import React, { Suspense } from 'react';
import { FullPageLoading } from '@/components';

const App = React.lazy(() => import('./App'));

const Root: React.FC = () => (
  <Suspense fallback={<FullPageLoading />}>
    <App />
  </Suspense>
);

export default Root;
