import React from 'react';
import styled from 'styled-components';
import SunIcon from '@/assets/svg/sun.svg';

const FooterContainer = styled.footer`
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: url('${SunIcon}') no-repeat center bottom;
  min-height: 108px;
  padding: 5px 8px;
  align-items: flex-end;

  @media screen and (max-width: 400px) {
    background-size: 50%;
  }
`;

export const Footer: React.FC = () => (
  <FooterContainer>

  </FooterContainer>
);
