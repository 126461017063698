import styled from 'styled-components';
import React from 'react';
import { Footer } from './Footer';

const LayoutContainer = styled.main`
  display: flex;
  flex-direction: column;
  margin: auto;
  min-height: 100vh;

  @media screen and (min-width: 1024px) {
    width: 700px;
  }

  @media screen and (max-width: 400px) {
    max-width: 100%;
  }
`;

type LayoutPropsType = {
  children: React.ReactNode;
};

export const Layout: React.FC<LayoutPropsType> = ({ children }) => (
  <LayoutContainer>
    {children}
    <Footer />
  </LayoutContainer>
);
