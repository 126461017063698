import styled from 'styled-components';
import { classNames } from 'primereact/utils';
import React from 'react';
import { colors } from '@/theme';

export type FormikInputProps = {
  field: { name: string; value: any; onChange: () => void; onBlur: () => void };
  form: { touched: Record<string, any>; errors: Record<string, any> };
};

const StyledInput = styled.input<{ invalid: boolean }>`
  height: 52px;
  width: 292px;
  padding: 10px 20px;
  font-size: 18px;
  border: 1px solid ${(props) => (props.invalid ? colors.red500 : colors.white)};

  &::placeholder {
    color: ${colors.gray200};
    font-style: italic;
  }
`;

export const FormikInput: React.FC<FormikInputProps> = ({
  field,
  form,
  ...rest
}) => (
  <StyledInput
    {...field}
    value={field.value ?? ''}
    {...rest}
    invalid={!!form.errors[field.name]}
    className={classNames({
      'p-invalid': !!form.errors[field.name],
    })}
  />
);
