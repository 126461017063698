import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { colors } from '@/theme';
import ArrowSvg from '@/assets/svg/arrow.svg';

const StyledButton = styled.button`
  width: 62px;
  height: 62px;
  display: flex;
  border-radius: 50%;
  border: 2px solid ${colors.gray200};
  background: url('${ArrowSvg}') no-repeat center;
  cursor: pointer;
`;

type RoundButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const RoundButton: React.FC<RoundButtonProps> = (props) => (
  <StyledButton {...props} />
);
