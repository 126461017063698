import { configureStore } from '@reduxjs/toolkit';
import authReducer from './store/auth.slice';
import redeemReducer from './store/redeem.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    redeem: redeemReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
